/* eslint-disable no-plusplus */
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory, Link } from 'react-router-dom';
import { DealerContext } from '../contexts/DealerContext';
import { VehicleContext } from '../contexts/VehicleContext';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '100px 15px',
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 500px)': {
      padding: '0px 0px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      padding: '0px 0px',
    },
  },
  innerHolderFilter: {
    maxWidth: '1770px',
    width: '100%',
    margin: ' 0 auto',
  },
  innerHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: ' 0 auto',
    padding: '0px 50px',
    width: '80%',
    '@media (max-width: 500px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: '100%',
      padding: ' 20px 20px',
    },
  },
  card: {
    width: 'calc(100% / 3 - 40px )',
    minWidth: 'calc(100% / 3 - 40px )',
    maxWidth: 'calc(100% / 3 - 40px )',
    cursor: 'pointer',
    borderRadius: '10px',
    margin: '20px ',
    padding: '40px ',
    background: 'white',
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid #eef6f6',
    '&:hover': {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    '@media (max-width: 425px)': {
      minWidth: '100%',
      margin: '20px 0px',
    },
    '@media (min-width: 426px) and (max-width: 842px)': {
      minWidth: 'calc(100% / 2 - 20px )',
      margin: '  10px ',
    },
  },
  cardTextHolder: {
    flex: 1,
    padding: '0px',
    color: 'white',
    '@media (max-width: 768px)': {},
  },
  cardTextHolderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '5px 0px',
  },
  cardTextTitleHolder: {
    marginBottom: '10px',
    textAlign: 'center',
  },
  cardTextTitle: {
    fontSize: '19px',
    fontWeight: '500',
  },
  imageHolder: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  cardTextTitleAccent: {
    fontSize: '20px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
  },
  cardTextTitleAccentTop: {
    fontSize: '25px',
    color: 'black',
    margin: '10px 0px',
  },
  cardTextTitleAccentMore: {
    fontSize: '16px',
    color: ({ websiteColors }) => `${websiteColors.accentColor}`,
    textTransform: 'uppercase',
    margin: '5px 0px',
    '&:hover': {
      color: ({ websiteColors }) => `${websiteColors.primaryColor}`,
    },
    border: '2px solid #000',
    padding: '10px',
    borderRadius: '5px',
  },
  groupCard: {
    width: 'calc(100% / 3 - 40px )',
    minWidth: 'calc(100% / 3 - 40px )',
    cursor: 'pointer',
    borderRadius: '10px',
    margin: '20px ',
    padding: '10px ',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    border: '2px solid #eef6f6',
    '&:hover': {
      background: 'white',
      border: ({ websiteColors }) => `2px solid ${websiteColors.accentColor}`,
    },
    '@media (max-width: 425px)': {
      width: 'calc(100% / 1 )',
      minWidth: 'calc(100% / 1  )',
      margin: '20px 0px',
    },
    '@media (min-width: 426px) and (max-width: 842px)': {
      width: 'calc(100% / 2 - 20px )',
      margin: '  10px ',
    },
  },
  groupCardLogo: {
    display: 'flex',
    flex: 1,
    margin: '10px 0px',
    aspectRatio: '16/9',
    objectFit: 'contain',
  },
  groupCardImg: {
    display: 'flex',
    flex: 4,
    objectFit: 'contain',
  },
  btnHolder: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: ' 0 auto',
    padding: '0px 50px',
    width: '80%',
    '@media (max-width: 500px)': {
      width: '100%',
    },
    '@media (min-width:501px) and (max-width: 768px)': {
      width: '100%',
    },
  },
  btn: {
    padding: '10px 35px',
    border: 'none',
    cursor: 'pointer',
    background: '#083246',
    margin: '10px',
    borderRadius: '50px',
    color: 'white',
  },
  btnEx: {
    padding: '10px 35px',
    border: 'none',
    cursor: 'pointer',
    background: '#083246',
    margin: '10px',
    borderRadius: '50px',
    color: 'white',
  },
  h1: {
    fontFamily: 'OmodaR',
    width: '100%',
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '50px',
    marginTop: '0px',
    '@media (max-width: 600px)': {
      marginTop: '50px',
    },
  },
}));

const ShowRoom = () => {
  const history = useHistory();
  const { newVehiclesList } = useContext(VehicleContext);
  const { websiteColors } = useContext(DealerContext);
  const classes = useStyles({ websiteColors });
  const vehicles = newVehiclesList;

  const handleRedirect = (data) => {
    history.push(`/show-room/${data?.make}/${data?.ownedModelId}`, data);
  };

  const formatter = new Intl.NumberFormat('af', {
    style: 'currency',
    currency: 'ZAR',
    maximumFractionDigits: 0,
  });

  const onLinkClick = (e) => {
    e.preventDefault();
    history.push('/explore-c5');
  };
  const onLinkClick2 = (e) => {
    e.preventDefault();
    history.push('/explore-j7');
  };

  return (
    <div className={classes.root}>
      <h1 className={classes.h1}>MODELS</h1>
      <div className={classes.innerHolder}>
        {vehicles.flat().map((v, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`master_vehicle_card_${i}`} className={classes.card}>
            <div className={classes.imageHolder}>
              <img alt="" src={v?.image} width="100%" />
            </div>
            <div className={classes.cardTextHolder}>
              <div className={classes.cardTextTitleHolder}>
                <p className={classes.cardTextTitleAccentTop}>{v.title}</p>
                <h5 className={classes.cardTextTitle}>
                  <strong>
                    <span className={classes.cardTextTitleAccent}>
                      From {formatter.format(v?.price)}
                    </span>
                  </strong>
                </h5>
                <p
                  className={classes.cardTextTitleAccentMore}
                  onClick={() => {
                    handleRedirect(v);
                  }}
                >
                  More Details
                </p>
                {}
                <div className={classes.cardTextTitleAccentMore}>
                  <Link
                    to={`/explore-${v.title.toLowerCase()}`}
                    onClick={v.title === 'C5' ? onLinkClick : onLinkClick2}
                  >
                    {' '}
                    Explore {v.title}{' '}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShowRoom;
